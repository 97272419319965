@import "ng-multiselect-dropdown/themes/ng-multiselect-dropdown.theme";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/variables";

$selected-item-bg: $gray-600;
$selected-item-border: $gray-600;
$selected-item-text-color: $white;
$selected-item-close-btn-color: $white;
$selected-item-close-btn-bg-color: $gray-700;

ng-multiselect-dropdown {
	&.is-invalid .multiselect-dropdown {
		@extend .border-danger;
	}
}

.dropdown-multiselect__caret {
	top: 0 !important;
	right: 0 !important;
	height: 100% !important;

	&::before {
		height: $input-padding-x;
		top: 50% !important;
		display: inline-block;
		margin-top: 0 !important;
	}
}

.multiselect-dropdown {
	@extend .p-0;
	@extend .form-control;

	.dropdown-btn {
		@extend .border-0;
		padding: $input-padding-y calc($input-padding-x * 3) $input-padding-y $input-padding-x !important;
	}

	.selected-item-container {
		.selected-item {
			@extend .py-0;
			@extend .ps-2;
			@extend .pe-0;
			@extend .d-flex;
			@extend .rounded;

			max-width: 100% !important;
			background-color: $selected-item-bg !important;
			border: 1px solid $selected-item-border !important;
			margin: calc($input-padding-y / 2) calc($input-padding-x / 2) calc($input-padding-y / 2) 0;

			span {
				@extend .fs-6;
			}

			a {
				width: 25px;
				text-align: center;
				padding-left: 0 !important;
				@extend .rounded-end;

				&:hover {
					color: $selected-item-close-btn-color;
					background-color: $selected-item-close-btn-bg-color;
				}
			}

			&:hover {
				box-shadow: none !important;
			}
		}
	}

	.dropdown-list {
		@extend .pt-0;
		@extend .shadow-sm;
		@extend .rounded;

		left: -1px !important;
		border-color: $input-border-color !important;

		.filter-textbox {
			input {
				@extend .p-0;
			}
		}

		.multiselect-item-checkbox {
			padding: $input-btn-padding-y $input-btn-padding-x !important;

			input + div::before {
				border: 2px solid $selected-item-border !important;
			}

			input:checked + div::before {
				background-color: $selected-item-bg !important;
				border: 2px solid $selected-item-border !important;
			}
		}
		.no-filtered-data {
			h5 {
				@extend .fs-6;
				@extend .m-0;
				@extend .p-1;
			}
		}
	}
}
