@import '@ng-select/ng-select/themes/default.theme.css';

$primary-color: $primary;
$primary-color-hover: rgba($primary, 0.125);

.ng-select {
	&.ng-select-opened > .ng-select-container {
		border-bottom-color: transparent !important;
	}

	&.is-invalid .ng-select-container {
		border-color: $danger !important;
	}

	.ng-select-container {
		border-radius: $border-radius !important;
	}

	.ng-option {
		padding: 0.25rem 0.5rem !important;

		&.ng-option-disabled {
			cursor: default !important;
			background-color: transparent !important;
		}

		&:not(:last-child) {
			margin-bottom: 1px;
		}
	}

	.ng-option-selected:not(.ng-option-disabled) {
		background-color: $primary-color !important;
		color: $light !important;
	}

	.ng-option-selected {
		.ng-option-label {
			font-weight: $font-weight-base !important;
		}
	}

	// Hover
	.ng-option-marked {
		background-color: $primary-color-hover !important;
	}

	// Cor da borda do campo com foco
	&.ng-select-focused > .ng-select-container {
		border-left-color: $primary-color !important;
		border-right-color: $primary-color !important;
		border-top-color: $primary-color !important;
		border-bottom-color: $primary-color !important;

		&:not(.ng-select-open) {
			box-shadow: $input-focus-box-shadow !important;
		}
	}

	&.ng-select-opened > .ng-select-container {
		border-bottom-color: transparent !important;
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}

	&.ng-select-focused > .ng-dropdown-panel {
		border-bottom-color: $primary-color !important;
		border-left-color: $primary-color !important;
		border-right-color: $primary-color !important;
	}

	// Cor da badge quando multi-select estiver ativo
	&.ng-select-multiple .ng-value {
		color: $white !important;
		background-color: $primary-color !important;
		border-radius: $border-radius-sm !important;

		.ng-value-icon,
		.ng-value-label {
			padding: 1px $input-btn-padding-x-sm !important;
		}

		.ng-value-icon:hover {
			background-color: $danger !important;
			border-top-left-radius: $border-radius-sm !important;
			border-bottom-left-radius: $border-radius-sm !important;
		}
	}
}
