.dt-container.dt-bootstrap5 {
	padding: 0;
	max-width: 100%;
	@include media-breakpoint-down(md) {
		overflow: auto;
	}

	.row.mt-2.justify-content-md-center {
		overflow: auto !important;
		div.col-12 {
			width: 100% !important;
		}
	}

	th:hover {
		outline: none !important;
	}

	.dt-search {
		@include media-breakpoint-down(md) {
			display: flex;
		}

		& label {
			display: none;
		}

		& input {
			width: auto;
			@extend .form-control;
			@include media-breakpoint-down(md) {
				display: flex;
				flex-grow: 1;
				margin: 0;
			}
		}
	}

	.dt-paging a {
		font-size: $font-size-sm;
	}

	.dt-info {
		@extend .p-0;
		font-size: $font-size-sm;
	}
}
