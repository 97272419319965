.sidebar-open {
	overflow: hidden !important;
}

.is-loading {
	overflow: hidden !important;
}

.toast-container {
	top: $navbar-height;
}

.d-contents {
	display: contents !important;
}

.cursor {
	&--pointer {
		cursor: pointer;
	}
}

.btn {
	@extend .text-nowrap;
}

.accordion:not(.border-0) {
	@extend .shadow-sm;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

th {
	@extend .fw-semibold;
	vertical-align: middle !important;
}

@each $breakpoint, $value in $grid-breakpoints {
	@for $i from 0 through 20 {
		@include media-breakpoint-up($breakpoint) {
			.w-#{$breakpoint}-#{$i * 5} {
				width: calc($i * 5%) !important;
			}
		}
	}
}
