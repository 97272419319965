@keyframes red-zoom-loader-animation {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}


.red-zoom {
    pointer-events: none;
}
// overlay, error
.red-zoom__overlay,
.red-zoom__error {
    position: absolute;
    left: var(--red-zoom-thumb-x, 0px);
    top: var(--red-zoom-thumb-y, 0px);
    width: var(--red-zoom-thumb-w, 0px);
    height: var(--red-zoom-thumb-h, 0px);
}
// window
.red-zoom__lens {
    display: flex;
}
.red-zoom__lens-body {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
}
.red-zoom__lens-image {
    --red-zoom-lens-image-x: calc(
        var(--red-zoom-lens-image-base-x, 0px) +
        var(--red-zoom-lens-image-center-offset-x, 0px)
    );
    --red-zoom-lens-image-y: calc(
        var(--red-zoom-lens-image-base-y, 0px) +
        var(--red-zoom-lens-image-center-offset-y, 0px)
    );

    position: absolute;
    transform:
        translateX(var(--red-zoom-lens-image-x))
        translateY(var(--red-zoom-lens-image-y))
        translateZ(0);
}
// lens
.red-zoom__frame {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--red-zoom-frame-w, 0px);
    height: var(--red-zoom-frame-h, 0px);
    transform:
        translateX(var(--red-zoom-frame-x, 0px))
        translateY(var(--red-zoom-frame-y, 0px));
}
.red-zoom__frame-body {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.red-zoom__frame-image {
    width: var(--red-zoom-thumb-w, 0px);
    height: var(--red-zoom-thumb-h, 0px);
    transform:
        translateX(var(--red-zoom-frame-image-x, 0px))
        translateY(var(--red-zoom-frame-image-y, 0px))
        translateZ(0);
}
