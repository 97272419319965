@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');

@import 'bootstrap/scss/functions';
@import './variables';
@import 'bootstrap/scss/variables';
@import './overrides';

@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/root';

@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/helpers';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/offcanvas';
@import 'bootstrap/scss/placeholders';
@import 'bootstrap/scss/utilities/api';

// Custom changes
@import './custom';

// Bootstrap Icons
@import 'bootstrap-icons/font/bootstrap-icons.css';

// Angular DataTables
@import './datatables';

// Angular MultiSelect Dropdown
@import './multiselect-dropdown';

// Angular Image Zoom
@import 'ngx-red-zoom/styles/base.scss';
@import 'ngx-red-zoom/styles/style-overlay.scss';

// Angular Ng-Select
@import "./ng-select";
