$grid-columns: 24;
$navbar-height: 80px;

// Colors
$blue: #0d1136;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1820px,
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1740px,
);

$input-btn-padding-y: 0.325rem;
$input-btn-padding-x: 0.5rem;
$input-btn-font-size: 0.95rem;

$accordion-padding-y: 0.625rem;
$accordion-padding-x: 0.875rem;
$accordion-body-padding-y: $accordion-padding-x;
$accordion-body-padding-x: $accordion-padding-x;
