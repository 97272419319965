$font-family-base: 'Syne', 'Roboto', arial, sans-serif;
$font-size-base: 0.9rem;
$small-font-size: $font-size-base * 0.75;

$hr-opacity: 1;
$hr-color: $border-color;
$hr-bg-color: $border-color;

$border-radius: 0.2rem;
$border-radius-sm: 0.125rem;
$border-radius-lg: 0.25rem;
$border-radius-xl: 0.5rem;
$border-radius-xxl: 1rem;

$body-color: $black;
$body-secondary-color: rgba($body-color, 0.85);
